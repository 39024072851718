import React, { useState, useEffect, memo } from "react";
import "@fontsource/inter";
import landingImage1 from "../../images/landing1.png";
import landingImage2 from "../../images/landing2.png";
import landingImage3 from "../../images/landing3.png";
import landingImage4 from "../../images/landing4.png";
import orangeLogo from "../../images/leksa-icon.png";
import instagramLogo from "../../images/instagram-logo.png";
import appStoreLogo from "../../images/appstore-logo.png";
import "./LandingPage.css";

// Static array so it isn't recreated on every render
const landingImages = [landingImage1, landingImage2, landingImage3, landingImage4];

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % landingImages.length);
    }, 1500); // Change image every 1.5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container">
      {/* Header */}
      <header className="header">
        <div className="header-logo">
          <img src={orangeLogo} alt="Leksa Logo" className="logo" />
          <h1 className="header-text desktop-only">Leksa</h1>
        </div>
        <div className="header-buttons desktop-only">
          <a
            href="https://instagram.com/joinleksa"
            target="_blank"
            className="header-button-white"
            rel="noreferrer"
          >
            Our Instagram
          </a>
          <a
            href="https://apps.apple.com/us/app/leksa/id6738683516"
            target="_blank"
            className="header-button-orange"
            rel="noreferrer"
          >
            Get the App
          </a>
        </div>
        <div className="header-buttons mobile-only">
          <a
            href="https://instagram.com/joinleksa"
            target="_blank"
            className="header-button-icon"
            rel="noreferrer"
          >
            <img src={instagramLogo} alt="Instagram" className="icon" />
          </a>
          <a
            href="https://apps.apple.com/us/app/leksa/id6738683516"
            target="_blank"
            className="header-button-icon"
            rel="noreferrer"
          >
            <img src={appStoreLogo} alt="App Store" className="icon" />
          </a>
        </div>
      </header>

      <div className="content-wrapper">
        {/* Text Section */}
        <section className="text-section">
          <p className="heading">Explore your campus culture, through food.</p>
          <p className="sub-heading">🎓 Student-Exclusive</p>
          <p className="sub-heading">🎯 Personalized Picks</p>
          <p className="sub-heading sub-heading-margin">🗺️ Social & Local</p>
          <p className="description">
            Leksa gives you real recommendations from students like you, so you always know what spots are actually good.
          </p>
          <a
            href="https://apps.apple.com/us/app/leksa/id6738683516"
            target="_blank"
            className="button"
            rel="noreferrer"
          >
            Get the App
          </a>
        </section>

        {/* Image Section */}
        <section className="image-section">
          <img
            src={landingImages[currentIndex]}
            alt="Landing"
            className="landing-image"
          />
        </section>
      </div>

      {/* Footer */}
      <footer className="footer">
        <p className="footer-text">© 2025 Julian Zulfikar</p>
        <a href="/tos" className="footer-link">
          Terms of Service
        </a>
        <a href="/privacy" className="footer-link">
          Privacy Policy
        </a>
      </footer>
    </div>
  );
};

export default memo(LandingPage);
